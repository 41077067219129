import React from "react"
import Container from "../../components/container"
import Head from "../../components/head"
import {getNames} from "country-list"
import NodeGarden from "../../components/new/nodeGarden/nodeGarden"
import Grid from "../../components/new/grid/grid"
import Layout from "../../components/new/layout/layout"
import { useStaticQuery, graphql } from "gatsby"
import Paragraph from "../../components/new/paragraph/paragraph"
import SubHeader from "../../components/new/subHeader/subHeader"
import HubspotContactFormPartner from "../../components/hubspotContactFormPartner"


export default function BookDemo () {
	const data = useStaticQuery(graphql`
		query {
			allContentfulCustomer(filter: {featured:{eq: true}} limit: 4) {
				edges {
					node {
						logo
						title
						featured
						url
					}
				}
			}
		}
	`)
	const countries = getNames()
    return (
		<Layout navbar='darkerblue'>
			<Head title="Transform Your Marketplace Operations Now" description="Ensure the future of your marketplace by booking a demo of the Onport Platform now."/>
			<Container style={{classes: "container-fluid bg-contact-lg py-5" }}>
				<Container style={{classes: "container position-relative" }}>
					<div className="row mt-5 pt-5 align-items-start">
						<div className="col-12 col-md-6 py-5 mt-5">
							<SubHeader title='Talk to an Onport marketplace expert' style='light'/>
                        	<Paragraph text='Discover how the Onport multi-vendor marketplace solution can deliver on your business needs.' style='light w-75 mb-4'/>
                        	<Paragraph text='The call will focus on:' style='light w-75 mb-4 xxl'/>
                        	<Paragraph text='Identifying the pain points of your ecommerce operation' style='light w-75 mb-4'/>
                        	<Paragraph text='Understanding if Onport is the right fit for your needs' style='light w-75 mb-4'/>
                        	<Paragraph text='Get visibility on the ideal pricing package that best suits your online business' style='light w-75 mb-4'/>
                        	<Paragraph text='We can support you at any stage of your journey with future-proof composable technology so that you may manage your ecommerce marketplace operations from one single place.' style='light w-75'/>
							<div className='mt-5 text-white'style={{background: 'rgba(255, 255, 255, 0.1)', padding: '1rem', borderRadius:'8px' }}>
								<p className='quote mb-4' style={{fontStyle: 'italic', fontSize: '1.2rem'}}>Onport allows us to win, but also the brands we work with to win, because they make the invoicing, shipping, set up, and catalog management processes all so simple.</p>
								<div className="d-flex align-items-center">
									<img src='https://media.licdn.com/dms/image/D4E03AQH9IuPzmlI2WA/profile-displayphoto-shrink_400_400/0/1700038009967?e=2147483647&v=beta&t=C5yN9bIa1Ot9fJNREttgsX-qQxFGsOBbDLx7xVtn0y8' style={{height: '60px', width: '60px', borderRadius: '100%', objectFit:'cover', filter: 'grayscale(100%)'}}/>
									<div className="ml-3">
										<p style={{fontWeight:'900', fontSize: '1rem'}}>Catherine Cornelissen</p>
										<p style={{fontSize: '1rem'}}>Founder | Decoralist</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 bg-lol2 pt-4">
								<h2 className="text-white pl-4" style={{fontWeight:'bold'}}>Take the first step today</h2>
								<HubspotContactFormPartner id="37f648e6-3818-427c-ac51-b184f81ad31c"/>
						</div>
					</div>
					<Grid style='grid-4 mt-5 py-4'>
								{data.allContentfulCustomer.edges.map((edge) => {
									if (edge.node.featured) {
										return(
											<a href={edge.node.url} target="_blank">
												<img src={edge.node.logo} alt={edge.node.title} style={{opacity: '0.6', width:'160px', filter:'invert(100%) saturate(0)', margin: '1rem 0'}}/>
											</a>
										)
									}
								})}
					</Grid>
				</Container>	
			</Container>
		</Layout>
    )
}